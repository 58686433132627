@import "../../../assets/css/mixin.scss";
@import "../../../assets/css/variables.scss";
.rt-signupholder{
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 80px 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: url(../../../assets/images/signup-bg.jpg) no-repeat;
    background-size: cover;
    .rt-formbox{
        width: 900px;
        height: auto;
        background: $white;
        padding: 56px 40px 40px;
        @include bd-radius(20px);
    }
    h3{
        color: $nero;
        font-size: 1.5rem;
        @include norwester;
        margin-bottom: 25px;
    }
    .rt-alreadyaccount{
        display: block;
        padding: 20px 0;
        font-size: 16px;
        color: $suvagrey;
        @include norwester;
        a{
            color: $nero;
            display: inline-block;
            text-decoration: underline;
        }
    }
    .mt-30{
        margin-top: 30px;
    }
    .rt-customradio-area{
        label{
            position: static;
            margin-right: 10px;
            transform: translate(0);
        }
    }
    span{
        color: $nero;
        @include norwester;
        display: inline-block;
        vertical-align: middle;
    }
    .rt-customcheckbox-area{
        margin-bottom: 20px;
        span{
            color: $nero;
            font-size: 16px;
            @include roboto-regular;
            a{
                color: $nero;
                @include roboto-bold;
            }
        }
    }
    .rt-heading{
        h2{
            color: $white;
            @include norwester;
            margin-bottom: 25px;
            font-size: 2.438rem;
        }
    }
}
.rt-membershipplan{
    width: auto;
    overflow: hidden;
    min-width: 650px;
    background: $white;   
    border-radius: 20px;
    margin: 0 auto 24px;
}

.rt-tabcontentarea{
    padding: 30px;

    .rt-roundicon{
        width: 100px;
        height: 100px;
        margin-right: 20px;
        line-height: 100px;
        border: 2px solid $nero;
        background: $electriclime;

        i{
            color: $nero;
            font-size: 3.125rem;
        }
    }

    .rt-themecontent{
        span{
            color: $nero;
            display: block;
            font-size: 16px;
            margin-bottom: 8px;
            @include roboto-regular;
            text-transform: capitalize;

            strong{
                font-size: 1.5rem;
                @include norwester;
                display: inline-block;
            }
        }

        h4{
            margin-bottom: 8px;
            font-size: 1.375rem;
            @include roboto-medium;
            text-transform: capitalize;
        }

        em{
            color: $silver;
            display: block;
            font-size: 14px;
            font-style: normal;
            @include roboto-regular;
            text-transform: capitalize;
        }
    }
}
.rt-customradiobox{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*========================================
            Responsive 
==========================================*/

@media (max-width: 1440px) {
    .rt-signupholder{
        height: 100%;
    }
}
@media (max-width: 991px) {
    .rt-signupholder{
        padding: 80px 30px;
    }
    .rt-signupholder .rt-formbox{
        width: 100%;
    }
}
@media (max-width: 767px) {
    .rt-membershipplan{
        min-width: 100%;
    }
}
@media (max-width: 639px) {
    .rt-signupholder {
        padding: 50px 20px;
    }
    .rt-formbox{
        padding: 30px 15px !important;
    }
    .rt-signupholder h3{
        margin-bottom: 15px;
    }
    .rt-signupholder .mt-30{
        margin-top: 0;
    }
}
@media (max-width: 567px) {
    html {
        font-size: 75%;
    }
}
@media (max-width: 479px) {
    .rt-navtabs .nav-item{
        width: 100%;
    }
    .rt-tabcontentarea{
        padding: 20px;

        .rt-themecardbox{
            flex-direction: column;
        }

        .rt-roundicon{
            margin: 0 0 20px;
        }

        .rt-themecontent{
            text-align: center;
        }
    }
    .rt-customradiobox{
        height: auto;
        flex-direction: column;
        align-items: flex-start;

        .rt-customradio-area{
            margin-bottom: 10px;
        }
    }
}

.credit-card-input {
    position: initial !important;
}