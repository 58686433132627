@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-dashboard{
    padding: 0;
    display: flex;

    .rt-categores{
        grid-template-columns: repeat(auto-fill,minmax(278px,1fr));
        grid-gap: 24px;
        margin-bottom: 24px;
    }
}
.rt-statandcategoryarea{
    margin-right: 24px;
    width: calc(100% - 395px);
}
.rt-statistics{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(489px,1fr));
    grid-gap: 24px;
    margin-bottom: 24px;
}
.rt-statistic{
    display: flex;
    padding: 30px;
    background: $nero;
    align-items: center;
    @include bd-radius(20px);
    justify-content: space-between;

    .rt-content{
        padding: 0;
        em{
            display: block;
            font-size: 3.5rem;
            font-style: normal;
            @include norwester;
            margin-bottom: 12px;
            color: $electriclime;
        }
        h3{
            margin: 0;
            color: $white;
            font-size: 2rem;
            span{
                display: block;
            }
        }
    }
    .rt-iconbox{
        i{
            font-size: 6.75rem;
            color: $electriclime;
        }
    }
}

.rt-sessionslistarea{
    width: 425px;
    padding: 30px 0;
    height: 1145px;
    background: $nero;
    @include bd-radius(20px);

    .rt-title{
        margin: 0;
        padding: 20px 0;
        h2{
            color: $electriclime;
        }

        &:first-child{
            padding-top: 0;
        }
    }
}
.rt-sessionslistholder{
    height: 100%;
    padding: 0 30px;
    overflow-y: auto;
    @include scrollbarnone;
}
.rt-sessionslist{
    .rt-themecardbox{
        padding: 12px 0;
        
        a{
            display: block;
            @include transition;

            &:hover{
                h4{
                    color: $electriclime;
                }
            }
        }
    }

    .rt-rectangelbox{
        width: 56px;
        height: 56px;
        padding: 4px 0;
        display: block;
        line-height: 23px;
        margin-right: 12px;
        font-size: 1.25rem;
        text-align: center;
        @include norwester;
        color: $electriclime;
        @include bd-radius(8px);
        border: 1px solid $electriclime;

        em{
            display: block;
            font-style: normal;
        }
    }

    .rt-themecontent{
        h4{
            color: $white;
            @include transition;
        }
        span{
            display: block;
            font-size: 16px;
            color: $suvagrey;
            text-transform: uppercase;
        }
    }
}
.rt-schedule{
    background: $nero;
    padding-bottom: 25px;
    @include bd-radius(16px);

    .rt-title{
        margin: 0;
        padding: 32px 40px;
        border-bottom: 1px solid $NightRider;

        h2{
            color: $white;
            font-size: 2.5rem;
        }
    }
    .fc-theme-standard{
        height: 586px;
    }

    .fc .fc-toolbar.fc-header-toolbar{
        margin: 20px 0;
        padding: 0 40px;
    }
    .fc-header-toolbar .fc-today-button{
        display: none;
    }
    .fc-header-toolbar .fc-toolbar-title{
        color: $white;
        @include norwester;
        font-size: 1.875rem;
    }
    .fc-header-toolbar .fc-prev-button,
    .fc-header-toolbar .fc-next-button{
        border: 0;
        padding: 0;
        color: #fff;
        box-shadow: none;
        border-radius: 0;
        background: transparent;
    }
    .fc-header-toolbar .fc-prev-button:hover,
    .fc-header-toolbar .fc-next-button:hover,
    .fc-header-toolbar .fc-prev-button:focus,
    .fc-header-toolbar .fc-next-button:focus{
        border: 0;
        outline: none;
        box-shadow: none;
        background: transparent;
    }
    .fc-header-toolbar .fc-prev-button .fc-icon,
    .fc-header-toolbar .fc-next-button .fc-icon{
        font-size: 30px;
    }

    .fc-col-header{
        // margin: 0;
    }

    .fc-scrollgrid-sync-table{
        margin: 0;
    }

    .fc-theme-standard .fc-scrollgrid{
        margin: 0;
        border: 0;
    }

    .fc-theme-standard td, .fc-theme-standard th{
        border: 0;
        color: $white;
    }

    .fc-theme-standard th .fc-col-header-cell-cushion {
        color: $white;
        font-size: 1.25rem;
    }

    .fc-theme-standard td a{
        color: $white;
        font-size: 18px;
        text-align: center;
    }
    
    .fc-daygrid-day{
        line-height:18px;
    }

    .fc-daygrid-day .fc-daygrid-day-top{
        width: 60px;
        height: 60px;
        margin: 0 auto;
        cursor: pointer;
        text-align:center;
        line-height: 60px;
        align-items:center;
        justify-content: center;
        border:2px solid $white;
        @include bd-radius(50%);
    }

    .fc .fc-daygrid-day.fc-day-today{
        background: transparent;
    }

    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
        top: 60px;
        z-index: 10;
        display: none;
        min-height: auto;
        overflow: hidden;
        position: absolute;
        @include transition;
        @include bd-radius(14px);

        .fc-daygrid-event-harness{
            padding: 0 16px;
            background: $white;
        }

        .fc-daygrid-event{
            border: 0;
            margin: 0;
            color: $nero;
            padding: 6px 0;
            text-align: left;
            @include norwester;
            background: transparent;
        }

        .fc-event-main{
            color: $nero;
            @include norwester;
        }
    }

    .fc .fc-daygrid-day-frame:hover .fc-daygrid-day-events{
        display: block;
    }
}
/*========================================
            Responsive 
==========================================*/

@media (max-width: 1900px) {
    .rt-statistics{
        grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    }
    .rt-categores{
        grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
    }

}

@media (max-width: 1800px) {
    .rt-categores {
        grid-template-columns: repeat(auto-fill, minmax(243px, 1fr));
    }
}

@media (max-width: 1660px) {
    .rt-statistic .rt-content em{
        font-size: 3rem;
    }
    .rt-statistic .rt-content h3{
        font-size: 1.6rem;
    }
    .rt-statistic .rt-iconbox i{
        font-size: 5.75rem;
    }
    .rt-dashboard .rt-categores {
        grid-template-columns: repeat(auto-fill,minmax(265px,1fr));
    }
}
@media (max-width: 1500px){
    .rt-dashboard .rt-categores {
        grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
    }
}
@media (max-width: 1440px) {
    .rt-statistic .rt-content h3 {
        font-size: 2rem;
    }
    .rt-statistic .rt-content em {
        font-size: 3.5rem;
    }
    .rt-statistic .rt-iconbox i {
        font-size: 6.75rem;
    }
    .rt-statistics {
        grid-template-columns: repeat(auto-fill, minmax(333px, 1fr));
    }
}

@media (max-width: 1393px){
    .rt-dashboard .rt-categores {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

@media (max-width: 1300px){
    .rt-statistics {
        grid-template-columns: repeat(auto-fill, minmax(312px, 1fr));
    }
    .rt-dashboard .rt-categores {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
}

@media (max-width: 1199px) {
    .rt-dashboard{
        flex-direction: column;
    }
    .rt-statandcategoryarea{
        width: 100%;
        margin: 0 0 24px;
    }
    .rt-sessionslistarea{
        width: 100%;
        height: 730px;
    }
    .rt-statistics {
        grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    }
    .rt-dashboard .rt-categores {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media (max-width: 991px) {
    .rt-dashboard .rt-categores {
        grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    }
}
@media (max-width: 600px) {
    .rt-schedule .rt-title{
        padding: 20px;
    }
    .rt-schedule .fc-theme-standard {
        height: 480px;
    }
    .rt-schedule .fc .fc-toolbar.fc-header-toolbar{
        padding: 0 20px;
    }
    .rt-schedule .fc-daygrid-day .fc-daygrid-day-top{
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}

@media (max-width: 479px) {
    .rt-statistic .rt-content em {
        font-size: 3rem;
    }
    .rt-statistic .rt-content h3 {
        font-size: 1.6rem;
    }
    .rt-statistic .rt-iconbox i {
        font-size: 5.75rem;
    }
    .rt-schedule .fc-theme-standard {
        height: 390px;
    }
    .rt-schedule .fc-theme-standard td a{
        font-size: 14px;
    }
    .rt-schedule .fc-daygrid-day .fc-daygrid-day-top {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}
@media (max-width: 374px) {
    .rt-statistics{
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
}

@media (max-width: 359px) {
    .rt-sessionslistholder{
        padding: 0 20px;
    }
    .rt-sessionslist .rt-themecardbox {
        flex-direction: column;
        align-items: flex-start;
    }
    .rt-sessionslist .rt-rectangelbox{
        margin: 0 0 12px;
    }
}

.fc-day-today .fc-daygrid-day-top {
    background-color: yellow !important;
}
.fc-day-today .fc-daygrid-day-number {
    color: black !important;
}