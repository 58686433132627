@import "../../assets/css/mixin.scss";
@import "../../assets/css/variables.scss";

.rt-leaderboard{
    .rt-pageheading{
        justify-content: space-between;
    }
    .rt-navtabs{
        margin: 0;
        width: auto;
        background: transparent;

        .nav-item{
            width: auto;
            margin: 0 1px;
            border-radius: 10px 0 0 10px;

            &:last-child{
                border-radius: 0 10px 10px 0;
            }

            a{
                font-size: 16px;
                color: $PinkSwan;
                padding: 15px 30px;
                &.active{
                    color: $white;
                    background: $nero;
                }
            }
        }
    }
}
.rt-teamboard{
    display: flex;
    padding: 30px 0;
    @include cardlayout;
    margin-bottom: 24px;
    align-items: center;
    justify-content: space-around;

    .rt-roundimage{
        width: 100px;
        height: 100px;
        margin-right: 16px;
        line-height: 100px;
    }

    .rt-themecontent{
        span{
            display: block;
            font-size: 1.25rem;
            @include norwester;
        }
        h4{
            font-size: 1.25rem;
        }
        em{
            display: block;
            font-style: normal;
            font-size: 1.25rem;
            @include norwester;
        }
    }
}

.rt-table{
    margin: 0;
    overflow: hidden;
    @include bd-radius(16px);
    thead{
        tr{
            th{
                color: $nero;
                font-size: 16px;
                font-weight: 500;
                @include norwester;
                letter-spacing: 1px;
                background: $Gainsboro;
                text-transform: uppercase;
            }
        }
    }
    tbody{
        tr{
            td{
                padding: 0;
                font-size: 16px;
                vertical-align: middle;
                background: $whisperfive;
                border-bottom: 1px solid $Gainsboro;
                a{
                    display: block;
                    padding: 18px 30px;
                    .rt-roundicon{
                        position: static;
                        margin-left: 10px;
                    }
                }
                .rt-roundimage{
                    width: 40px;
                    height: 40px;
                    margin: 0 auto;
                    line-height: 40px;
                }
            }
        }
    } 
}
.rt-pagination{
    float: none;
    justify-content: end;
    .page-item{
        overflow: hidden;
        background: $whisperfive;
        &:hover,
        &.active{
            .page-link{
                border: 0;
                color: $white;
                background: $nero;
            }
        }
        .page-link{
            border: 0;
            color: $nero;
            height: 50px;
            padding: 0 20px;
            line-height: 50px;
            font-size: 1.125rem;
            border-radius: 14px;
            @include transition;
            margin: 0 !important;
            @include roboto-regular;
            background: $whisperfive;
            &:focus{
                box-shadow: none;
            }
        }
        &:nth-last-child(2){
            border-radius: 0 14px 14px 0;
        }
        &:nth-child(2){
            border-radius: 14px 0 0 14px;
        }
    }

    .rt-prev,
    .rt-next{
        &:hover{
            .page-link{
                span{
                    color: $white;
                }
            }
        }
        margin: 0 10px 0 0;
        @include bd-radius(14px);
        .page-link{
            border: 0;
            padding: 0;
            width: 50px;
            height: 50px;
            display: block;
            line-height: 50px;
            text-align: center;
            background: $whisperfive;

            span{
                float: none;
                color: $nero;
                display: block;
                font-size: 40px;
                line-height: 40px;
                text-align: center;
                &.sr-only{
                    display: none;
                }
            }
        }
    }
    .rt-next{
        margin: 0 0 0 10px;
    }
    .sr-only{
        display: none;
    }
}

/* =============================================
            Responsive
============================================= */

@media (max-width: 767px) {
    .rt-teamboard .rt-roundimage{
        width: 70px;
        height: 70px;
        line-height: 70px;
    }
    .rt-pagination .page-item .page-link{
        height: 40px;
        line-height: 40px;
    }
    .rt-pagination .rt-prev .page-link,
    .rt-pagination .rt-next .page-link{
        width: 45px;
    }
    .rt-pagination .rt-prev .page-link span,
    .rt-pagination .rt-next .page-link span{
        line-height: 35px;
    }
}
@media (max-width: 479px) {
    .rt-leaderboard .rt-navtabs .nav-item a{
        padding: 12px 20px;
    }
    .rt-leaderboard .rt-pageheading{
        flex-direction: column;
        align-items: flex-start;
    }
    .rt-leaderboard .rt-pageheading .rt-title{
        margin-bottom: 15px;
    }
    .rt-teamboard{
        padding: 30px;
        flex-direction: column;
        align-items: flex-start;
        .rt-themecardbox{
            margin-bottom: 15px;
            &:last-child{
                margin: 0;
            }
        }
    }
}